<template>
    <v-content>
        <v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs>
        <v-tabs>
            <v-tab href="#tab-selling-rate">Selling Rate</v-tab>
            <v-tab href="#tab-buying-rate">Buying Rate</v-tab>
            <v-tab-item
                    value="tab-selling-rate"
            >
                <sellingRate></sellingRate>
            </v-tab-item>
            <v-tab-item
                    value="tab-buying-rate"
            >
                <buyingRate></buyingRate>
            </v-tab-item>

        </v-tabs>
    </v-content>
</template>

<script>
    import sellingRate from '../SellingRate/List'
    import buyingRate from '../BuyingRate/List'

    export default {
        name: "Exchange Services",
        components: {
            sellingRate,
            buyingRate
        },
        data() {
            return {
                breadCrumb: [
                    {
                        text: 'Setting',
                        disabled: false,
                        to: 'Income-GenerationSettings',
                        exact: true

                    },
                    {
                        text: 'Exchange Rate',
                        disabled: true,
                    },
                ],
                snackbar: false,
                success: false,
            };
        },

        mounted() {

        },
        methods: {}
    };
</script>

<style lang="scss" scoped>
    .v-item-group {
        background: #d2e3ff73;
    }

    .v-expansion-panel-header {
        background: #d2e3ff73;
    }
</style>